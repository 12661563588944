import React, { ReactElement } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import { BlogDetailQuery } from "../../types/gatsby-graphql"

interface Props extends PageProps {
  data: BlogDetailQuery
}

// export default function BlogPost({ data }: PageProps) {
export default function BlogPost({ data }: Props): ReactElement {
  const post = data.markdownRemark

  return (
    <Layout>
      <div>
        <h1 className="text-red-500">{post?.frontmatter?.title}</h1>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: post?.html || "" }} />

        <h2>Frontmatter Images</h2>
        {post?.frontmatter?.images?.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${index}-cl`}>
            <img className="my-4" alt="something" src={image || ""} />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogDetail($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        something
        images
      }
    }
  }
`
